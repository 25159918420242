import React, { useEffect, useState } from "react";
import style from "./Product.module.css";
import { withRouter } from "react-router";
import { useHistory, Link } from "react-router-dom";
import { staticsproduct } from "./staticsproduct";

const ProductList = (props) => {
  const history = useHistory();
  const [booklist, setBooklist] = useState([]);
  const [category, setCategory] = useState("");
  const [subcategory, setSubCategory] = useState("");

  const getHeaderMenu = () => {
    return new Promise((resolve, reject) => {
      if (props.store.menulist && props.store.menulist.length > 0) {
        resolve(props.store.menulist);
      } else {
        api
          .getHeaderMenue()
          .then((res) => {
            if (res && res.data) {
              mixins.setStore("menulist", res.data, props);
              resolve(res.data);
            } else {
              reject();
            }
          })
          .catch(reject);
      }
    });
  };

  const getBooklist = (menulist = props.store.menulist) => {
    const hasDynamicRoute = menulist.find((o) =>
      o.url.startsWith("/" + props.match.params.bookCategory)
    );
    const data = {
      category: hasDynamicRoute?.category || "",
      subCategory: props.match.params.bookSubCatgeory || "",
    };
    setCategory(data.category);
    setSubCategory(data.subCategory);
    //console.log({ hasDynamicRoute, menulist, category: props.match.params.bookCategory });
    api
      .getBooklist(data)
      .then((res) => {
        if (res.statusCode === 200 && res.data) {
          mixins.setStore("customCharacter", [], props);
          mixins.setStore("customeBookId", "", props);
          mixins.setStore("product", "", props);
          mixins.setStore("productCustomPayload", "", props);
          if (data.category == "custom") {
            let tempbooklist = [...res.data, staticsproduct];
            setBooklist(tempbooklist);
          } else {
            setBooklist(res.data);
          }
        }
      })
      .catch(() => {
        setBooklist([]);
      });
  };
  useEffect(() => {
    localStorage.setItem("editmode", false);
  }, []);

  useEffect(() => {
    getHeaderMenu().then((menulist) => {
      getBooklist(menulist);
    });
  }, [props.match.params.bookCategory, props.match.params.bookSubCatgeory]);

  //Custom Book Static Code

  return (
    <div className={style["main"]}>
      <ul className={style["cards"]}>
        {booklist &&
          booklist
            .filter((o) => o.display == 1)
            .map((data, index) => {
              return (
                <li className={style["cards_item"]} key={index}>
                  <div className={style["card"]}>
                    <div>
                      <img
                        src={data && data.fileData && data.fileData.filePath}
                        className={style["card_image"]}
                      />
                    </div>
                    <div className={style["card_content"]}>
                      <div className={style["row-container"]}>
                        <div className={style["left-container"]}>
                          <div className={style["card_title"]}>
                            {data.bookTitle}
                          </div>
                        </div>
                      </div>
                      <p className={style["card_text"]}>
                        {data.shortDescription}
                      </p>
                      <div className={style["button-container"]}>
                        <div style={{ width: "70%" }}>
                          {category === "custom" ? (
                            <Link
                              to={`/custom-books/product-details/${data.id}`}
                            >
                              <button className={style["btn"]}>
                                Find out more
                              </button>
                            </Link>
                          ) : category === "self-publishing" ? (
                            subcategory === "picture" ? (
                              <Link
                                to={`/self-publishing/product-details/${data.id}`}
                              >
                                <button className={style["btn"]}>
                                  Find out more
                                </button>
                              </Link>
                            ) : subcategory === "chapter" ? (
                              <Link
                                to={`/self-publishing/product-details/${data.id}`}
                              >
                                <button className={style["btn"]}>
                                  Find out more
                                </button>
                              </Link>
                            ) : null
                          ) : category === "handprint" ? (
                            <Link
                              to={`/handprint-books/product-details/${data.id}`}
                            >
                              <button className={style["btn"]}>
                                Find out more
                              </button>
                            </Link>
                          ) : null}
                        </div>
                        <div className={style["price"]}>
                          {"S$" + data.bookPrice}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
      </ul>
    </div>
  );
};
export default connect(withRouter(ProductList));
