import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import "./../HandPrint.css";
import AppInput from "../../../partials/AppInput";
import { useHistory } from "react-router-dom";

const Preinfo = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [alertFail, setAlertFail] = useState(null);
  const history = useHistory();

  const { onSave = () => {} } = props;
  const worldbookPreInfo = props.store?.book?.worldbookPreInfo || {};
  let answer = {
    ...Object.deepClone(worldbookPreInfo),
    ...(worldbookPreInfo?.answer || {}),
  };

  const handleInput = (e) => {
    let inputValue = e.target.value;
    const words = inputValue.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
    );
    e.target.value = capitalizedWords.join(" ");
  };

  const handleChange = (val) => {
    answer = { ...Object.deepClone(answer), ...val };
    let payload = Object.deepClone(answer);
    payload.answer = answer;
  };

  const steps = ["1", "2"];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="maincontainer">
            <div className="first-row">Child's name.</div>
            <AppInput
              className="second-row"
              type="text"
              variant="filled"
              label="Child name"
              inputProps={{ pattern: "[a-zA-Z ]*" }}
              defaultValue={answer?.answer?.child_name || ""}
              onChange={(e) => {
                handleInput(e);
                handleChange({ ...answer, child_name: e.target.value });
              }}
            />
          </div>
        );
      case 1:
        return (
          <div className="maincontainer">
            <div className="first-row">Book title</div>
            <AppInput
              className="second-row"
              type="text"
              variant="filled"
              label="Book title"
              inputProps={{ pattern: "[a-zA-Z ]*" }}
              defaultValue={answer?.answer?.book_title || ""}
              onChange={(e) => {
                handleInput(e);
                handleChange({ ...answer, book_title: e.target.value });
              }}
            />
          </div>
        );
      default:
        return "Unknown step";
    }
  };

  const handleNext = () => {
    delete answer.answer;
    let payload = Object.deepClone(answer);
    payload.answer = answer;
    mixins.setStore("book.worldbookPreInfo", payload, props);
    const stepsConfig = [
      { step: 0, field: "child_name" },
      { step: 1, field: "book_title" },
    ];

    const currentStep = stepsConfig.find((step) => step.step === activeStep);
    const validStep = answer[currentStep.field];
    if (validStep) {
      setActiveStep(activeStep + 1);
      if (isLast()) {
        onSave(answer, props.alert);
      }
    } else {
      setAlertFail("Enter the field");
      setTimeout(() => setAlertFail(null), 2000);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const isLast = () => activeStep === steps.length - 1;

  const goBack = () => {
    props.alert.close();
    history.push("/handprint-books/product-list");
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleChange();
              }}
            >
              <div
                className="PreInfoCollection worldbook"
                id="handprint_pre_info_collection"
              >
                {alertFail && (
                  <Alert severity="error" variant="filled" className="alert">
                    <strong>{alertFail}</strong>
                  </Alert>
                )}
                <div className="row">
                  <div className="col-12 step active">
                    {getStepContent(activeStep)}
                  </div>
                </div>
                <div className="width-custom d-flex align-item-center justify-content-between mt-5">
                  <div className="">
                    <div className="col-12">
                      <button
                        className="continue"
                        type="button"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </button>
                    </div>
                  </div>
                  <div className="">
                    {activeStep > 0 && (
                      <button
                        type="button"
                        className="previous"
                        onClick={handleBack}
                      >
                        BACK TO PREVIOUS STEP
                      </button>
                    )}
                    {activeStep === 0 && (
                      <button
                        className="previous"
                        type="button"
                        onClick={() => {
                          answer?.child_name
                            ? onSave(answer, props.alert)
                            : history.push(
                                `/world-books/product-details/${props.store.bookId}`
                              );
                        }}
                      >
                        BACK TO PRODUCT STEP
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Preinfo);
