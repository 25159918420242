import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import style from "./ProductDetails.module.css";
import { useHistory, Link } from "react-router-dom";
import p3 from "../../../assets/images/book_image.png";
import Personalize from "../../common/Personalize/Personalize";
import ProductCarrousal from "./carrousal/ProductCarrousal";
import { productdetails } from "./../productlist/staticsproduct";

const ProductDetails = (props) => {
  let { id } = useParams();
  const history = useHistory();
  const [bookDetails, setBookDetails] = useState([]);
  const [images, setImages] = useState([]);
  const [urlpreview, setUrlpreview] = useState("");
  const previewImages = [
    {
      url: "https://e-books-customization.s3.ap-southeast-1.amazonaws.com/e-book-api/files/uFYKn7Vnp6/Background.png",
    },
    {
      url: "https://e-books-customization.s3.ap-southeast-1.amazonaws.com/e-book-api/files/ofubJs90i0/New Project (1).png",
    },
    {
      url: "https://e-books-customization.s3.ap-southeast-1.amazonaws.com/e-book-api/files/RiljvIvEnk/cfyfycfyv.png",
    },
    {
      url: "https://e-books-customization.s3.ap-southeast-1.amazonaws.com/e-book-api/files/Qv1QNTCkMk/Self-publishing_product detail page_image2.jpg",
    },
  ];
  useEffect(() => {
    Modal.state.list.find((o) => o.type == "model")?.close();
    if (id) {
      getBookDetails(id);
    }
    mixins.setStore("book.handprintPreInfo", {}, props);
    mixins.setStore("book.worldbookPreInfo", {}, props);
  }, []);
  const getBookDetails = (id) => {
    if (id == 5) {
      let data = productdetails;
      setBookDetails(data.bookDetails);
      mixins.setStore("product", data.bookDetails.product, props);
      mixins.setStore("bookId", data.bookDetails.id, props);
      mixins.setStore("customCharacter", [], props);
      setUrlpreview(data.bookDetails.book.fileData.filePath);
    } else {
      api.getBookDetails(id).then((res) => {
        if (res.statusCode === 200 && res.data && res.data.bookDetails) {
          setBookDetails(res.data.bookDetails);
          setImages(res.data.images);
          if (res.data.bookDetails.product)
            mixins.setStore("product", res.data.bookDetails.product, props);
          mixins.setStore("bookId", res.data.bookDetails.id, props);
          mixins.setStore("customCharacter", [], props);
          setUrlpreview(res.data.bookDetails.book.fileData.filePath);
        }
      });
    }
  };
  const [moreDetailsflag, setMoreDetailsflag] = useState(false);
  const redirecting = () => {
    //console.log("data");
    let node = document.querySelector(
      "#accordionAboutUs>div>.MuiAccordionSummary-expandIconWrapper"
    );
    //console.log("node", node);
    if (!moreDetailsflag) {
      node.click();
      setMoreDetailsflag(true);
    }
    let temp = document.querySelector("#accordionAboutUs");
    document.scrollingElement.scrollBy(0, temp.offsetHeight + 100);
  };
  const personalizeNow = () => {
    history.push(`/book/customcharacter-book/${id}`);
  };
  const uploadHandprint = () => {
    history.push(`/book/handprint-book/${id}`);
  };
  localStorage.setItem("editmode", false);
  return (
    <div className={style["container"]}>
      <div className={style["product-details-container"]}>
        <div className={style["previewdivsection"]}>
          {images &&
            images.length > 1 &&
            images.map((item, key) => {
              return (
                <div>
                  {item != null && (
                    <button
                      key={key}
                      className={style["previewButtonimage"]}
                      onClick={() => {
                        setUrlpreview(item);
                      }}
                    >
                      <img src={item} style={{ height: "98px" }} />
                    </button>
                  )}
                </div>
              );
            })}
        </div>
        <div className={style["image-container1"]}>
          <img src={urlpreview} />
        </div>
        <div className={style["image-container2"]}>
          <ProductCarrousal images={images} />

          {/* <img src={bookDetails && bookDetails.book && bookDetails.book.fileData && bookDetails.book.fileData.filePath}  /> */}
        </div>
        <div className={style["product-des"]}>
          <div className={style["card_title"]}>
            {bookDetails && bookDetails.book && bookDetails.book.bookTitle}
          </div>

          {/* {bookDetails && bookDetails.product &&bookDetails.product.productInfo&& bookDetails.product.productInfo.length>=1&&
                     <Fragment><hr className={style["divider-solid"]} />
                     <div className={style['des-title']}>Product Info.</div></Fragment>
                    }
                   
                    <div className={style['des']}>
                        {bookDetails && bookDetails.product&&bookDetails.product.productInfo && bookDetails.product.productInfo.map((data, index) => {
                             return (
                            <label key={index}> {'- '+data}</label>)})}   
                        </div>*/}
          {/* <hr className={style["divider-solid"]} />  */}
          {/* <div className={style['des-title']}>{bookDetails && bookDetails.book &&'About the Book'}</div> */}

          <p
            className={style["card_text"]}
            dangerouslySetInnerHTML={{
              __html:
                (bookDetails &&
                  bookDetails.description &&
                  bookDetails.description.replace(/(\r\n|\n|\r)/gm, "<br>")) ||
                "",
            }}
          ></p>
          {/* <hr className={style["divider-solid"]} />  */}
          {bookDetails &&
            bookDetails.customHow &&
            bookDetails.customHow["0"].length > 0 &&
            bookDetails.book &&
            (bookDetails.book.category === "custom" ||
              bookDetails.book.category === "handprint") && (
              <div className={style["more-details"]} onClick={redirecting}>
                More Details
              </div>
            )}
          <div className={style["price"]}>
            {" "}
            {bookDetails &&
              bookDetails.book &&
              "S$" + bookDetails.book.bookPrice}
          </div>
          <div className={style["button-div"]}>
            {/* <button className={style["btn-add-cart"]} onClick={()=>{history.push('/checkout-page')}}>Add to Cart</button> */}
            {bookDetails &&
              bookDetails.book &&
              bookDetails.book.category === "world" && (
                <Link className={"customAnchor"} to={`/book/world-book/${id}`}>
                  <button
                    className={style["btn"]}
                    // onClick={() => history.push(`/book/customcharacter-book/${id}`)}
                  >
                    Personalise{" "}
                  </button>
                </Link>
              )}
            {bookDetails &&
              bookDetails.book &&
              bookDetails.book.category === "custom" && (
                <Link
                  className={"customAnchor"}
                  to={`/book/customcharacter-book/${id}`}
                >
                  <button
                    className={style["btn"]}
                    // onClick={() => history.push(`/book/customcharacter-book/${id}`)}
                  >
                    Personalise{" "}
                  </button>
                </Link>
              )}
            {bookDetails &&
              bookDetails.book &&
              bookDetails.book.category === "handprint" && (
                <Link
                  className={"customAnchor"}
                  to={`/book/handprint-book/${id}`}
                >
                  <button
                    className={style["btn"]}
                    // onClick={() => history.push(`/book/handprint-book/${id}`)}
                  >
                    Personalise{" "}
                  </button>
                </Link>
              )}
            {bookDetails &&
              bookDetails.book &&
              bookDetails.book.category === "self-publishing" &&
              bookDetails.ageGroups === "chapter" && (
                <Link
                  className={"customAnchor"}
                  to={`/book/chapter-book/${id}`}
                >
                  <button
                    className={style["btn"]}
                    // onClick={() => history.push(`/book/chapter-book/${id}`)}
                  >
                    Chapter Book Template{" "}
                  </button>
                </Link>
              )}
            {bookDetails &&
              bookDetails.book &&
              bookDetails.book.category === "self-publishing" &&
              bookDetails.ageGroups === "picture" && (
                <Link
                  className={"customAnchor"}
                  to={`/book/picture-book/${id}`}
                >
                  <button
                    className={style["btn"]}
                    // onClick={() => history.push(`/book/picture-book/${id}`)}
                  >
                    Picture Book Template{" "}
                  </button>
                </Link>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(ProductDetails);
