import React from "react";
import style from "./Pages.module.css";
import Footer from "../components/layouts/footer/Footer";
import Announcement from "../components/layouts/announcement/AnnouncementBar";
import Header from "../components/layouts/header/Header";
import VerifyOtp from "../components/auth/forgetPassword/VerifyOtp";

const Pagedetails = (props) => {
  return (
    <div className={style["container"]}>
      <Announcement />
      <Header current={props.location.pathname} />
      <div className="container">
        <h1>Hello World</h1>
      </div>
      <Footer />
    </div>
  );
};
export default connect(Pagedetails);
