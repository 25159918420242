import React, { Component } from "react";
import { withRouter } from "react-router";
import Footer from "../../components/layouts/footer/Footer";
import Announcement from "../../components/layouts/announcement/AnnouncementBar";
import Header from "../../components/layouts/header/Header";

import HandPrint from "../../components/HandPrint/HandPrint";
import PictureBook from "../../components/PictureBook/PictureBook";
import ChapterBook from "../../components/SelfPublish/SelfPublish";
import CustomCharector from "../../components/CustomCharector/CustomCharector";
import Thisisyourworld from "../../components/thisisyourworld/Worldbook";
import ThumbPrint from "../../components/ThumbPrint/ThumbPrint";
import Customtext from "../../components/CustomText/CustomText";

export class BookPage extends Component {
  constructor(props) {
    super(props);
    this.params = props.match.params;
    this.genpdf = this.params.genpdf;
    initialConstruct.bind(this)("BookPage");
    this.state = {
      CategoryBook: this.getBookComponent(),
    };
  }

  getBookComponent() {
    let name = location.pathname.split("/").filter((v) => v)[1];
    switch (this.params.name) {
      case "chapter-book":
        return ChapterBook;
      case "picture-book":
        return PictureBook;
      case "customcharacter-book":
        return CustomCharector;
      case "world-book":
        return Thisisyourworld;
      case "handprint-book":
        return HandPrint;
      case "thumbprint-book":
        return ThumbPrint;
      case "customtext-book":
        return Customtext;
    }
  }

  render() {
    let { CategoryBook } = this.state;
    return (
      <div>
        {this.genpdf ? (
          <>
            <CategoryBook genpdf={this.genpdf} />
          </>
        ) : (
          <>
            <Announcement />
            <Header />
            <CategoryBook genpdf={this.genpdf} />
            <Footer />
          </>
        )}
      </div>
    );
  }
}

export default connect(withRouter(BookPage));
