import React, { Component } from "react";
import { withRouter } from "react-router";
import CheckoutSteps from "../preview/CheckoutSteps/CheckoutSteps";
import HTMLFlipBook from "react-pageflip";
import "./HandprintView.css";

export class HandprintView extends Component {
  constructor(props) {
    super(props);
    const { onFetch = (...arg) => api.getSelfPublish(...arg) } = props;
    this.onFetch = onFetch;
    this.CUSTOMBOOKID = props.bookDetails.id;
    initialConstruct.bind(this)("BookPreview");
    this.$ele = React.createRef();
    this.state = {
      pages: [],
      bookDetails: null,
      productDetails: null,
      answers: {},
      parsedBook: null,
      bookobj: null,
    };
  }

  onBookSetup(bookobj) {
    this.setState({ bookobj });
    let previewPageNo = localStorage.getItem("previewPageNo");
    if (previewPageNo) {
      setTimeout(() => {
        bookobj.turnToPage(Number(previewPageNo));
        localStorage.removeItem("previewPageNo");
      }, 100);
    }
  }

  getPages() {
    let { getPayload } = this.props;
    return new Promise((resolve, reject) => {
      getPayload()
        .then((book) => {
          resolve(
            Object.entries(book).map(([key, value], i) => ({
              pagetype: key,
              name: key,
              label: i + 1,
              content: value.html,
            }))
          );
        })
        .catch((err) => reject(err));
    });
  }

  getProductDetails() {
    let self = this;
    let { bookDetails } = this.props;
    api.getBookDetails(bookDetails.bookId).then((res) => {
      self.setState({
        productDetails: res.data.bookDetails,
      });
    });
  }

  getBookDetails() {
    let { bookDetails } = this.props;
    return new Promise((resolve, reject) => {
      if (bookDetails.id) {
        this.onFetch(bookDetails.id).then((response) => {
          if (response.data && Object.keys(response.data).length) {
            let savedBookObj = response.data || { savedBook: "{}" };
            let parsedBook = JSON.parse(savedBookObj.savedBook);
            this.setState({
              bookDetails: savedBookObj,
              parsedBook,
            });
            resolve(savedBookObj);
          }
        });
      }
    });
  }

  componentDidMount() {
    window.HandprintView = this;
    this.getPages().then((pages) => {
      this.setState({
        pages,
      });
      this.getBookDetails().then(() => {
        this.getProductDetails();
      });
    });
  }

  componentDidUpdate() {}

  onPage(obj, i, arr) {
    return {
      ...obj,
      content: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: obj.content || "" }}></div>
          <div className="col text-center">
            <b>{obj.label}</b> / {arr.length}
          </div>
        </div>
      ),
    };
  }

  onAnswer(value, key) {
    let { answers } = this.state;
    let { onUpdate } = this.props;
    if (key === "dedication") {
      onUpdate(key, value);
    }
    this.setState({
      answers: {
        ...answers,
        [key]: value,
      },
    });
  }

  onDedicationSave() {
    let { saveBook } = this.props;
    let { bookname } = this.props;
    return new Promise((resolve, reject) => {
      saveBook()
        .then(() => {
          setTimeout(() => {
            if (bookname === "customcharacter-book") {
              localStorage.setItem("previewPageNo", 18);
            }
            resolve();
          }, 100);
        })
        .catch(reject);
    });
  }

  checkoutSave() {
    return new Promise((resolve, reject) => {
      this.onDedicationSave().then(() => {
        let { bookDetails } = this.state;
        this.setState({
          productDetails: { ...bookDetails, triggeradd2cart: true },
        });
      });
    });
  }

  addToCart() {
    let props = this.props;
    let { productDetails, answers } = this.state;
    let { saveBook, getPayload } = props;
    let { bookDetails } = this.state;
    let { history } = this;
    mixins.checkLogin(history).then(async (user) => {
      if (!productDetails) {
        this.checkoutSave();
        return;
      } else {
        let payload = await getPayload();
        await emit.trigger("updatebook", payload);
      }

      if (localStorage.getItem("editmode") === "true") {
        api
          .updateToCart(
            {
              promotionId: null,
              productId: productDetails?.product?.id,
              quantity: 1,
              price: productDetails?.book?.bookPrice,
              discount: null,
              bookCover: answers.cover || null,
              customProductId: props.store.cartdetails?.customProductId,
            },
            props.store.cartdetails?.id
          )
          .then((res) => {
            localStorage.setItem("editmode", false);
            if (res && res.statusCode === 200) {
              history.push("/checkout-page");
            } else {
              alert("Something went wrong please try again later..");
            }
          });
      } else {
        api
          .addToCart({
            promotionId: null,
            productId: productDetails?.product?.id,
            quantity: 1,
            price: productDetails?.book?.bookPrice,
            discount: null,
            bookCover: answers.cover || null,
            customBookId: bookDetails.customBook.id,
          })
          .then((res) => {
            if (res && res.statusCode === 200) {
              history.push("/checkout-page");
              mixins.reFetchSavedBookDetails(this.props);
            } else {
              alert("Something went wrong please try again later..");
            }
          });
      }
    });
  }

  render() {
    let { bookname } = this.props;
    let { pages, bookDetails, productDetails } = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className={`bookView handprintcus ${bookname}`}>
              {pages.length > 0 && (
                <HTMLFlipBook
                  width={200}
                  height={200}
                  size="stretch"
                  maxShadowOpacity={0.5}
                  showCover={true}
                  mobileScrollSupport={true}
                  className="demo-book"
                >
                  {pages.map((o, index) => {
                    return (
                      <div
                        key={index}
                        className={`demoPage ${
                          index === 0
                            ? "front-page"
                            : index === pages.length - 1
                            ? "backcover"
                            : index % 2 === 1
                            ? "left-page"
                            : "right-page"
                        }`}
                        style={{ position: "relative" }}
                        dangerouslySetInnerHTML={{ __html: o?.content }}
                      ></div>
                    );
                  })}
                </HTMLFlipBook>
              )}
            </div>
          </div>
        </div>

        {/* Replace the alert with the proper page preview */}
        <div className="row mt-5">
          <div className="col-md-12">
            <CheckoutSteps
              keys={this.getStepKeys()}
              addToCart={() => this.addToCart()}
              onAnswer={(v, k) => this.onAnswer(v, k)}
              onSave={(v) => this.onDedicationSave(v)}
              bookname={bookname}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(HandprintView);
