import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import style from "./CustomerReview.module.css";
import { Rating } from "@mui/material";
import bookSmall from "../../../assets/images/Group 16823.png";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { Modal, Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useForm } from "react-hook-form";
import validator from "validator";
import { productdetails } from "../productlist/staticsproduct";

const CustomerReview = (props) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    // p: 4,
    overflow: "hidden",
  };
  const [emailError, setEmailError] = useState("");

  const history = useHistory();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [email, setEmail] = useState("");
  let { id } = useParams();
  const { handleSubmit } = useForm({ mode: "onBlur" });
  const [bookDetails, setBookDetails] = useState([]);
  const [averageRating, setAverageRating] = useState([]);
  const [alert, setAlert] = useState(null);
  const [alertFail, setAlertFail] = useState(null);
  const [details, setDetails] = useState("");
  const [rate, setRate] = useState("");
  const [name, setName] = useState("");
  const [userData, setUserdata] = useState(null);

  const validateEmail = (e) => {
    var email = e.target.value;
    setEmailError("");
    setEmail(email);
    if (!validator.isEmail(email)) {
      setEmailError("Enter valid Email!");
    }
  };

  useEffect(() => {
    getuserProfileDetail();
    if (id) {
      getBookDetails(id);
    }
  }, [id]);
  const getBookDetails = (id) => {
    if (id == 5) {
      let data = productdetails;
      setBookDetails(data.bookDetails);
      setAverageRating(data.bookDetails.avgRating);
    } else {
      api.getBookDetails(id).then((res) => {
        if (res.statusCode === 200 && res.data && res.data.bookDetails) {
          setBookDetails(res.data.bookDetails);
          if (res.data.bookDetails.avgRating) {
            setAverageRating(res.data.bookDetails.avgRating);
          }
        }
      });
    }
  };

  //getting username
  const getuserProfileDetail = () => {
    api.getuserProfile().then((res) => {
      if (res && res.data && res.statusCode === 200) {
        setUserdata(res.data);
        setName(res.data.firstName + " " + res.data.lastName);
      }
    });
  };

  //like and dislike
  const postReviewFeedback = (bookReviewId, feedback) => {
    api
      .postReviewFeedback({
        bookReviewId: bookReviewId,
        feedback: feedback,
      })
      .then((res) => {
        if (res.statusCode === 200) {
          // console.log(res.data);
          getBookDetails(id);
        }
      });
  };
  const OnHandleclickSubcribe = () => {
    //console.log(email);
    mixins.checkLogin(history).then((user) => {
      if (user.roles[0].name === "customer") {
        api
          .referyourfriend({
            bookId: bookDetails?.bookId,
            referredTo: email,
          })
          .then((res) => {
            if (res.statusCode === 200) {
              //console.log(res.data);
              setEmail("");
              setAlert(res.message);
              setTimeout(() => {
                setAlert(null);
              }, 3000);
            } else {
              setAlertFail(res.errorMessages[0]);
              setTimeout(() => {
                setAlertFail(null);
              }, 3000);
            }
          });
      } else {
        alert("Sorry login user is not a customer");
      }
    });
  };
  //submit review
  const onHandleClickPost = () => {
    //console.log("rate",rate)
    if (rate && name) {
      api
        .postBookReview({
          bookId: id,
          review: details,
          name: name,
          ratings: rate,
        })
        .then((res) => {
          if (res && res.statusCode === 200) {
            getBookDetails(id);
            setAlert(res.message);
            setTimeout(() => {
              setAlert(null);
              handleClose();
            }, 2000);
            setDetails("");
            setName("");
            setRate("");
          } else {
            setAlertFail(res.message);
            setTimeout(() => {
              setAlertFail(null);
            }, 2000);
          }
        });
    } else if (name === "") {
      setAlertFail("Please Enter Your Name");
      setTimeout(() => {
        setAlertFail(null);
      }, 2000);
    } else {
      setAlertFail("Please Enter Your Rating");
      setTimeout(() => {
        setAlertFail(null);
      }, 2000);
    }
  };
  return (
    <div className={style["container"]}>
      <div className={style["title"]}>Customer Reviews</div>
      <div className={style["book-review"]}>
        <div className={style["book-image-div"]}>
          <img
            src={
              bookDetails &&
              bookDetails.book &&
              bookDetails.book.fileData &&
              bookDetails.book.fileData.filePath
            }
          />
        </div>
        <div className={style["book-review-details"]}>
          <div className={style["book-rating"]}>
            {" "}
            {averageRating.averageRating &&
              averageRating.averageRating + " out of 5"}{" "}
          </div>
          <div className={style["star-rating"]}>
            {averageRating &&
              averageRating.averageRating &&
              averageRating.averageRating >= 1 && (
                <div>
                  <Rating
                    name="simple-controlled"
                    precision={0.5}
                    value={Number(averageRating.averageRating)}
                  />{" "}
                </div>
              )}
            <div className={style["small-text"]}>
              {averageRating.totalRating &&
                averageRating.totalRating + " " + "Ratings"}
            </div>
          </div>
          <button className={style["btn"]} onClick={handleOpen}>
            Write a review
          </button>
          <Modal open={open} onClose={handleClose}>
            <Box sx={modalStyle}>
              {alert && (
                <Alert severity="success" variant="filled">
                  <strong>{alert}</strong>
                </Alert>
              )}
              {alertFail && (
                <Alert severity="error" variant="filled">
                  <strong>{alertFail}</strong>
                </Alert>
              )}
              <div className={style["modal-header"]}>
                <img src={bookSmall} className={style["modal-icon"]} /> Rate and
                Review
              </div>
              <div className={style["modal-contents"]}>
                <div className={style["modal-title-container"]}>
                  <div className={style["modal-img-container"]}>
                    <img
                      src={
                        bookDetails &&
                        bookDetails.book &&
                        bookDetails.book.fileData &&
                        bookDetails.book.fileData.filePath
                      }
                      className={style["modal-img"]}
                    />
                  </div>
                  <div className={style["modal-des-container"]}>
                    <div className={style["modal-title"]}>
                      {bookDetails &&
                        bookDetails.book &&
                        bookDetails.book.bookTitle}
                    </div>
                    <div className={style["modal-sub-title"]}>
                      Your review will be posted publicly on the web
                    </div>
                    <div className={style["modal-sub-title"]}>
                      <label className={style["label"]}>
                        Your Ratings<span style={{ color: "red" }}> * </span>
                      </label>
                      <Rating
                        name="simple-controlled"
                        value={Number(rate)}
                        precision={0.5}
                        onChange={(event, newValue) => {
                          setRate(newValue);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {userData == null ? (
                  <input
                    type="name"
                    placeholder="Your Name *"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={style["modal-input"]}
                  />
                ) : (
                  <div></div>
                )}
                <textarea
                  type="textarea"
                  name="details"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                  className={style["modal-textarea"]}
                  placeholder="Share details of your own experience at this place"
                />

                <div className={style["modal-footer"]}>
                  <button
                    className={style["modal-close-post"]}
                    onClick={onHandleClickPost}
                  >
                    POST
                  </button>
                  <button
                    className={style["modal-close-btn"]}
                    onClick={handleClose}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
      {bookDetails &&
        bookDetails.bookReviews &&
        bookDetails.bookReviews.length >= 1 && (
          <hr className={style["divider-solid"]} />
        )}
      <div className={style["filter-div"]}>
        {bookDetails &&
          bookDetails.bookReviews &&
          bookDetails.bookReviews.length >= 1 && (
            <div className={style["review-count"]}>
              {" "}
              {"Showing " + bookDetails.totalReview + " of  "}{" "}
              {bookDetails.totalReview + " Reviews"}{" "}
            </div>
          )}
      </div>
      {bookDetails &&
        bookDetails.bookReviews &&
        bookDetails.bookReviews.length >= 1 && (
          <Fragment>
            <hr className={style["divider-solid"]} />
            <div className={style["amazing-reviews"]}>
              {bookDetails &&
                bookDetails.bookReviews &&
                bookDetails.bookReviews.map((data, index) => {
                  return (
                    <Fragment key={index}>
                      <div className={style["review-container"]}>
                        <div className={style["reviewer-image"]}>
                          <div className={style["reviewer-profile"]}>
                            {" "}
                            {data.name ? data.name.charAt(0) : "N"}
                          </div>
                        </div>
                        <div className={style["review-container-details"]}>
                          <div
                            className={style["review-container-rating-details"]}
                          >
                            {data.ratings && (
                              <Rating
                                name="simple-controlled"
                                precision={0.5}
                                value={Number(data.ratings)}
                              />
                            )}
                            <div className={style["review-name"]}>
                              {`By ` + (data.name ? data.name : null) + ` `}
                              {data.created_at.substr(0, 10)}
                            </div>
                          </div>
                          <div className={style["review-description"]}>
                            <p>{data.review}</p>
                          </div>
                          <div className={style["review-comment"]}>
                            <div className={style["normal-text"]}>
                              Is this helpful?
                            </div>
                            <ThumbUpIcon
                              className={style["review-icon"]}
                              onClick={() => postReviewFeedback(data.id, 1)}
                            />
                            <div className={style["review-feedback"]}>
                              {data.reviewFeedback &&
                                data.reviewFeedback.positive}{" "}
                            </div>
                            <ThumbDownIcon
                              className={style["review-icon"]}
                              onClick={() => postReviewFeedback(data.id, 0)}
                            />
                            <div className={style["review-feedback"]}>
                              {" "}
                              {data.reviewFeedback &&
                                data.reviewFeedback.negative}
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className={style["divider-solid-fill"]} />
                    </Fragment>
                  );
                })}
            </div>
          </Fragment>
        )}
      {/* {bookDetails?.product?.referral === "true" &&  */}
      <div className={style["referralcontainer"]}>
        <form
          className={style["referralcontainer"]}
          onSubmit={handleSubmit(OnHandleclickSubcribe)}
        >
          <div className={style["title"]}> Refer Your Friend</div>
          <div className={style["referralsubcontainer"]}>
            <input
              className={style["input"]}
              placeholder=" Enter your Friend Email "
              name={email}
              value={email}
              type="email"
              required
              // onChange={(e) => {
              //   setEmail(e.target.value);
              // }}
              onChange={(e) => validateEmail(e)}
            />
            <button type="submit" className={style["submitbuttonreferral"]}>
              Refer
            </button>
          </div>
          {emailError && (
            <span
              style={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {emailError}
            </span>
          )}

          {alertFail && (
            <Alert severity="error" variant="filled">
              <strong>{alertFail}</strong>
            </Alert>
          )}

          {alert && (
            <Alert severity="success" variant="filled">
              <strong>{alert}</strong>
            </Alert>
          )}
        </form>
      </div>
      {/* } */}
    </div>
  );
};
export default connect(CustomerReview);
