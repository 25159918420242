import React, { useState } from "react";

const Imagesuplods = (props) => {
  const { onSave = () => {} } = props;
  const worldbookPreInfo = props.store?.book?.worldbookPreInfo || {};
  let answer = worldbookPreInfo?.answer || {};
  const [formdata, setFormdata] = useState(answer?.image || {});
  const [imageassets, setImageassets] = useState(answer?.image_assets || {});
  const handleChange = (val) => {
    setFormdata(val);
  };
  const handleSubmit = () => {
    answer = {
      ...Object.deepClone(answer),
      image: formdata,
      image_assets: imageassets,
    };
    let payload = Object.deepClone(answer);
    payload.answer = answer;
    mixins.setStore("book.worldbookPreInfo", payload, props);
    onSave();
  };
  const handleCancel = () => {
    answer = {
      ...Object.deepClone(answer),
      image: formdata,
      image_assets: imageassets,
    };
    let payload = Object.deepClone(answer);
    payload.answer = answer;
    mixins.setStore("book.worldbookPreInfo", payload, props);
    onSave();
  };
  return (
    <div className="container worldbook">
      <div className="row">
        <div className="col-md-12">
          <form className="form" onSubmit={handleSubmit}>
            <div className="col-8 offset-2">
              <div className="row">
                <div className="col-md-12">
                  <h4>Upload Images:</h4>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="file"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setFormdata({
                          ...formdata,
                          image_one: e.target.files[0],
                        });
                        setImageassets({
                          ...imageassets,
                          image_one: url,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setFormdata({
                          ...formdata,
                          image_two: e.target.files[0],
                        });
                        setImageassets({
                          ...imageassets,
                          image_two: url,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setFormdata({
                          ...formdata,
                          image_three: e.target.files[0],
                        });
                        setImageassets({
                          ...imageassets,
                          image_three: url,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setFormdata({
                          ...formdata,
                          image_four: e.target.files[0],
                        });
                        setImageassets({
                          ...imageassets,
                          image_four: url,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setFormdata({
                          ...formdata,
                          image_five: e.target.files[0],
                        });
                        setImageassets({
                          ...imageassets,
                          image_five: url,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setFormdata({
                          ...formdata,
                          image_six: e.target.files[0],
                        });
                        setImageassets({
                          ...imageassets,
                          image_six: url,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setFormdata({
                          ...formdata,
                          image_seven: e.target.files[0],
                        });
                        setImageassets({
                          ...imageassets,
                          image_seven: url,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setFormdata({
                          ...formdata,
                          image_eight: e.target.files[0],
                        });
                        setImageassets({
                          ...imageassets,
                          image_eight: url,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      onChange={(e) => {
                        let url = URL.createObjectURL(e.target.files[0]);
                        setFormdata({
                          ...formdata,
                          image_nine: e.target.files[0],
                        });
                        setImageassets({
                          ...imageassets,
                          image_nine: url,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex align-items-center justify-content-between">
                    <button
                      className="continue"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                    <button className="previous" onClick={handleCancel}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(Imagesuplods);
