import React, { useRef, useState } from "react";
import Cropper from "cropperjs";
import Forward5Icon from "@mui/icons-material/Forward5";
import "cropperjs/dist/cropper.min.css";
import "./cropper.css";

const ImageCropper = ({
  onCropImage,
  inputImg,
  imgName,
  closeModal,
  ratio,
}) => {
  const imageElement = useRef(null);
  const [cropper, setCropper] = useState(null);
  const [alignment, setAlignment] = useState(false);
  const showCroppedImage = () => {
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        const maxSizeInBytes = 1024 * 1024;
        let compressionQuality = 1.0;
        while (blob.size > maxSizeInBytes && compressionQuality > 0.1) {
          compressionQuality -= 0.1;
          blob = cropper
            .getCroppedCanvas()
            .toBlob({ type: "image/jpeg", quality: compressionQuality });
        }
        const fileName = imgName || "croppedImage.jpg";
        const compressedFile = new File([blob], fileName, {
          type: "image/jpeg",
          lastModified: new Date().getTime(),
        });

        onCropImage(compressedFile);
        cropper.destroy();
        setCropper(null);
        closeModal();
      }, "image/jpeg");
    } else {
      setCropper(new Cropper(imageElement.current));
    }
  };
  const rotateImage = () => {
    if (cropper) {
      cropper.rotate(5); // Rotate image by 5 degrees
    } else {
      setCropper(new Cropper(imageElement.current));
    }
  };
  const rotateImageClockwise = () => {
    if (cropper) {
      cropper.rotate(-5); // Rotate image by 5 degrees
    } else {
      setCropper(new Cropper(imageElement.current));
    }
  };
  return (
    <>
      <div className={`cropper ${alignment ? "right" : "left"}`}>
        <div
          style={{
            border: "1px solid #00008b",
            height: "400px",
          }}
        >
          <img
            ref={imageElement}
            src={inputImg}
            alt="Input Image"
            style={{
              // width: "400px",
              height: "400px",
            }}
          />
        </div>

        <div className="d-flex justify-content-between align-items-center mt-2">
          <button onClick={closeModal} className="btn-cancel">
            Cancel
          </button>

          <span onClick={rotateImageClockwise} className="rotate">
            <Forward5Icon
              sx={{
                color: "#00008b",
                transform: "scaleX(-1) rotate(45deg)",
                cursor: "pointer",
              }}
            />
          </span>
          <span onClick={rotateImage} className="rotate">
            <Forward5Icon
              sx={{
                color: "#00008b",
                transform: "rotate(45deg)",
                cursor: "pointer",
              }}
            />
          </span>
          <button
            onClick={() => {
              showCroppedImage();
            }}
            className="btn-save"
          >
            {cropper ? "Save" : "Crop"}
          </button>
        </div>
      </div>
    </>
  );
};

export default ImageCropper;
