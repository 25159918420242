import React, { useState } from "react";

const Bookinfo = (props) => {
  const { onSave = () => {} } = props;
  const worldbookPreInfo = props.store?.book?.worldbookPreInfo || {};
  let answer = worldbookPreInfo?.answer || {};
  const [formdata, setFormdata] = useState(answer);
  const handleChange = (val) => {
    setFormdata(val);
  };
  const handleSubmit = () => {
    answer = { ...Object.deepClone(formdata) };
    let payload = Object.deepClone(answer);
    payload.answer = answer;
    mixins.setStore("book.worldbookPreInfo", payload, props);
    onSave();
  };
  const handleCancel = () => {
    answer = { ...Object.deepClone(formdata) };
    let payload = Object.deepClone(answer);
    payload.answer = answer;
    mixins.setStore("book.worldbookPreInfo", payload, props);
    onSave();
  };
  return (
    <div className="container worldbook">
      <div className="row">
        <form className="form" onSubmit={handleSubmit}>
          <div className="col-8 offset-2">
            <div className="row">
              <div className="col-md-12">
                <h4>Menu Items:</h4>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Please Enter Your Favourite Food"
                    className="form-control"
                    value={formdata?.menu_one || ""}
                    onChange={(e) => {
                      handleChange({ ...formdata, menu_one: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Please Enter Your Favourite Food"
                    className="form-control"
                    value={formdata?.menu_two || ""}
                    onChange={(e) => {
                      handleChange({ ...formdata, menu_two: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Please enter your favourite snack"
                    className="form-control"
                    value={formdata?.menu_three || ""}
                    onChange={(e) => {
                      handleChange({ ...formdata, menu_three: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Please enter your favourite drink"
                    className="form-control"
                    value={formdata?.menu_four || ""}
                    onChange={(e) => {
                      handleChange({ ...formdata, menu_four: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Please enter your favourite drink"
                    className="form-control"
                    value={formdata?.menu_five || ""}
                    onChange={(e) => {
                      handleChange({ ...formdata, menu_five: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <h4>Favourite place:</h4>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Please add your Favourite place"
                    onChange={(e) => {
                      handleChange({ ...formdata, fav_place: e.target.value });
                    }}
                  >
                    {formdata?.fav_place || ""}
                  </textarea>
                </div>
              </div>
              <div className="col-md-12">
                <h4>Inspirational messages:</h4>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Please enter your positive/inspirational messages"
                    className="form-control"
                    value={formdata?.message_one || ""}
                    onChange={(e) => {
                      handleChange({
                        ...formdata,
                        message_one: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Please enter your positive/inspirational messages"
                    className="form-control"
                    value={formdata?.message_two || ""}
                    onChange={(e) => {
                      handleChange({
                        ...formdata,
                        message_two: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Please enter your positive/inspirational messages"
                    className="form-control"
                    value={formdata?.message_three || ""}
                    onChange={(e) => {
                      handleChange({
                        ...formdata,
                        message_three: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <h4>Year:</h4>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Please enter year"
                    className="form-control"
                    value={formdata?.year || ""}
                    onChange={(e) => {
                      handleChange({
                        ...formdata,
                        year: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-center justify-content-between">
                  <button
                    className="continue"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                  <button className="previous" onClick={handleCancel}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect(Bookinfo);
