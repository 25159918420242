import ProductDetailsPage from "../pages/ProductDetailsPage";
import Home from "../pages/Home";
import ProductListPage from "../pages/ProductListPage";
import CheckoutPage from "../pages/CheckoutPage";
import PreviewPage from "../pages/PreviewPage";
//import SelfPublising from "../pages/SelfPublishing";
//import HandPrint from "../pages/HandPrint";
//import CustomCharector from "../pages/CustomCharector";
import SignupPage from "../pages/SignupPage";
import LoginPage from "../pages/LoginPage";
import ForgetPasswordPage from "../pages/ForgetPasswordPage";
import VerifyOtpPage from "../pages/VerifyOtpPage";
import NewPasswordPage from "../pages/NewPasswordPage";
import ShippingDetailspage from "../pages/ShippingDetailspage";
import DeliveryPage from "../pages/DeliveryPage";
import PaymentPage from "../pages/PaymentPage";

import DashboardPage from "../pages/DashboardPage";
import AuthorDashboardPage from "../pages/AuthorDashboardPage";
import ReactPaypal from "../components/payment/checkout/ReactPaypal";
import previewSelfPublish from "../pages/previewSelfPublish";
import terms from "../pages/terms";
import privacy from "../pages/privacy";
import returns from "../pages/return";
import faq from "../pages/faq";
import characterpreview from "../pages/character";
import about from "../pages/about";
import contact from "../pages/contact";
import orderSummary from "../pages/orderSummary";
import orderConfirmation from "../pages/orderConfirmation";
import previewHandPrint from "../pages/previewHandPrint";
//import HandprintUpload from "../components/HandPrintOld/HandprintUpload";
import Test from "../pages/Test";
//import PictureBook from "../pages/PictureBook";
//import BookPreviewPage from "../pages/BookPreviewPage";
import BookPage from "../pages/Books/BookPage";
import SubscriptionPayment from "../components/payment/checkout/SubscriptionPayment";
import Pagedetails from "../pages/Pagedetails";

//import Customtext from "../pages/Customtext";

const routes = [
  {
    exact: true,
    path: "/",
    component: Home,
  },
  {
    path: "/test",
    component: Test,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/:bookCategory/product-list/:bookSubCatgeory?",
    component: ProductListPage,
    exact: true,
  },
  {
    path: `/custom-books/product-details/:id`,
    component: ProductDetailsPage,
  },
  {
    path: `/self-publishing/product-details/:id`,
    component: ProductDetailsPage,
  },
  {
    path: `/handprint-books/product-details/:id`,
    component: ProductDetailsPage,
  },
  {
    path: "/checkout-page",
    component: CheckoutPage,
  },
  {
    path: "/shipping-details",
    component: ShippingDetailspage,
  },
  {
    path: `/edit-address/:code`,
    component: ShippingDetailspage,
  },

  {
    path: "/delivery",
    component: DeliveryPage,
  },
  {
    path: "/payment",
    component: PaymentPage,
  },
  {
    path: `/preview-book/:id`,
    component: PreviewPage,
  },
  {
    path: "/book/:name/:id/:customBookId?/:genpdf?",
    component: BookPage,
    exact: true,
  },
  {
    path: "/signup",
    component: SignupPage,
  },
  {
    path: "/sign-up/:id",
    component: SignupPage,
  },
  {
    path: "/login",
    component: LoginPage,
  },

  {
    path: `/signIn/:id`,
    component: LoginPage,
  },
  {
    path: "/forgot-password",
    component: ForgetPasswordPage,
  },
  {
    path: "/otp-verify",
    component: VerifyOtpPage,
  },
  {
    path: "/new-password",
    component: NewPasswordPage,
  },
  {
    path: "/dashboard",
    component: DashboardPage,
  },
  {
    path: "/paypal",
    component: ReactPaypal,
  },
  {
    path: "/subscriptionpayment",
    component: SubscriptionPayment,
  },
  {
    path: `/preview-selfPublish/:id`,
    component: previewSelfPublish,
  },
  // {
  //   path: `/book-preview/:id/:productid`,
  //   component: BookPreviewPage
  // },
  {
    path: `/preview-handPrint/:id`,
    component: previewHandPrint,
  },
  {
    path: "/terms",
    component: terms,
  },
  {
    path: "/privacy",
    component: privacy,
  },
  {
    path: "/delivery-returns",
    component: returns,
  },
  {
    path: "/faq",
    component: faq,
  },
  {
    path: "/character-new",
    component: characterpreview,
  },
  {
    path: "/about",
    component: about,
  },
  {
    path: "/contact",
    component: contact,
  },
  {
    path: "/order",
    component: orderSummary,
  },
  {
    path: "/order-confirmation",
    component: orderConfirmation,
  },
  {
    path: "/dummy-page",
    component: Pagedetails,
  },
  // {
  //   path: "/uploadhandprint",
  //   component: HandprintUpload,
  // },
  // {
  //   path: "/customtext",
  //   component: Customtext,
  // },
];
export default routes;
