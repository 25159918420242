import React, { useEffect, useState } from "react";
import "./HandprintView.css";
import HTMLFlipBook from "react-pageflip";
import Pageone from "./txtpages/Page1.txt";
import Pagetwo from "./txtpages/Page2.txt";
import Pagethree from "./txtpages/Page3.txt";
import Pagefour from "./txtpages/Page4.txt";
import Pagefive from "./txtpages/Page5.txt";
import Pagesix from "./txtpages/Page6.txt";
import Pageseven from "./txtpages/Page7.txt";
import Pageeight from "./txtpages/Page8.txt";
import Pagenine from "./txtpages/Page9.txt";
import Pageten from "./txtpages/Page10.txt";
import Pageeleven from "./txtpages/Page11.txt";
import Pagetwelve from "./txtpages/Page12.txt";
import Pagethirteen from "./txtpages/Page13.txt";
import Pageforteen from "./txtpages/Page14.txt";
import Pagefifteen from "./txtpages/Page15.txt";
import Pagesixteen from "./txtpages/Page16.txt";
import Pageseventeen from "./txtpages/Page17.txt";
import Pageeighteen from "./txtpages/Page18.txt";
import Pageninteen from "./txtpages/Page19.txt";
import Pagetwenty from "./txtpages/Page20.txt";
import Pagetwentyone from "./txtpages/Page21.txt";
import Pagetwentytwo from "./txtpages/Page22.txt";
import Pagetwentythree from "./txtpages/Page23.txt";
import Pagetwentyfour from "./txtpages/Page24.txt";
import Pagetwentyfive from "./txtpages/Page25.txt";
import Pagetwentysix from "./txtpages/Page26.txt";
import Pagetwentyseven from "./txtpages/Page27.txt";
import Pagetwentyeight from "./txtpages/Page28.txt";

const Worldbookview = (props) => {
  const [pages, setPages] = useState([]);
  const worldbookPreInfo = props.store?.book?.worldbookPreInfo;
  const answer = worldbookPreInfo?.answer;
  const fetchPages = async () => {
    try {
      const pageUrls = [
        Pageone,
        Pagetwo,
        Pagethree,
        Pagefour,
        Pagefive,
        Pagesix,
        Pageseven,
        Pageeight,
        Pagenine,
        Pageten,
        Pageeleven,
        Pagetwelve,
        Pagethirteen,
        Pageforteen,
        Pagefifteen,
        Pagesixteen,
        Pageseventeen,
        Pageeighteen,
        Pageninteen,
        Pagetwenty,
        Pagetwentyone,
        Pagetwentytwo,
        Pagetwentythree,
        Pagetwentyfour,
        Pagetwentyfive,
        Pagetwentysix,
        Pagetwentyseven,
        Pagetwentyeight,
      ];
      const pagePromises = pageUrls.map((pageUrl) =>
        fetch(pageUrl).then((res) => res.text())
      );

      const pagesContent = await Promise.all(pagePromises);
      const updatedPages = pagesContent.map((content) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, "text/html");
        if (answer?.child_name) {
          const elements = doc.querySelectorAll('[id="child_name"]');
          elements.forEach((element) => {
            element.textContent = answer?.child_name;
          });
        }
        if (answer?.menu_one) {
          const elements = doc.querySelectorAll('[id="custom_food_one"]');
          elements.forEach((element) => {
            element.textContent = answer?.menu_one;
          });
        }
        if (answer?.menu_two) {
          const elements = doc.querySelectorAll('[id="custom_food_two"]');
          elements.forEach((element) => {
            element.textContent = answer?.menu_two;
          });
        }
        if (answer?.menu_three) {
          const elements = doc.querySelectorAll('[id="custom_food_three"]');
          elements.forEach((element) => {
            element.textContent = answer?.menu_three;
          });
        }
        if (answer?.menu_four) {
          const elements = doc.querySelectorAll('[id="custom_food_four"]');
          elements.forEach((element) => {
            element.textContent = answer?.menu_four;
          });
        }
        if (answer?.menu_five) {
          const elements = doc.querySelectorAll('[id="custom_food_five"]');
          elements.forEach((element) => {
            element.textContent = answer?.menu_five;
          });
        }
        if (answer?.fav_place) {
          const elements = doc.querySelectorAll('[id="favourite_place"]');
          elements.forEach((element) => {
            element.textContent = answer?.fav_place;
          });
        }
        if (answer?.message_one) {
          const elements = doc.querySelectorAll('[id="message_one"]');
          elements.forEach((element) => {
            element.textContent = answer?.message_one;
          });
        }
        if (answer?.message_two) {
          const elements = doc.querySelectorAll('[id="message_two"]');
          elements.forEach((element) => {
            element.textContent = answer?.message_two;
          });
        }
        if (answer?.message_three) {
          const elements = doc.querySelectorAll('[id="message_three"]');
          elements.forEach((element) => {
            element.textContent = answer?.message_three;
          });
        }
        if (answer?.year) {
          const elements = doc.querySelectorAll('[id="custom_year"]');
          elements.forEach((element) => {
            element.textContent = answer?.year;
          });
        }
        if(answer?.book_title){
          const elements = doc.querySelectorAll('[id="book_title"]');
          elements.forEach((element) => {
            element.textContent = answer?.book_title;
          });
        }
        if (answer?.image_assets?.image_one) {
          const elements = doc.querySelectorAll('[id="image_one"]');
          elements.forEach((element) => {
            element.src = answer.image_assets.image_one;
          });
        }
        return new XMLSerializer().serializeToString(doc);
      });

      setPages(updatedPages);
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };
  useEffect(() => {
    fetchPages();
  }, [worldbookPreInfo]);

  return (
    <div className="HandPrint Worldbookview top-margin">
      <div className="container">
        <div className="row no-gutters space">
          <div className="col-12">
            <div className="handprint worldbook">
              {pages.length > 0 && (
                <HTMLFlipBook
                  width={200}
                  height={200}
                  size="stretch"
                  maxShadowOpacity={0.5}
                  showCover={true}
                  mobileScrollSupport={true}
                  className="demo-book"
                >
                  {pages.map((pageContent, index) => (
                    <div
                      key={index}
                      className={`demoPage ${
                        index === 0
                          ? "front-page"
                          : index === pages.length - 1
                          ? "backcover"
                          : index % 2 === 1
                          ? "left-page"
                          : "right-page"
                      }`}
                      style={{ position: "relative" }}
                      dangerouslySetInnerHTML={{ __html: pageContent }}
                    ></div>
                  ))}
                </HTMLFlipBook>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Worldbookview);
