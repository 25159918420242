import React, { useEffect, useState } from "react";
import Preinfo from "./popups/Preinfo";
import Worldbookview from "./Worldbookview";
import "./HandPrint.css";
import Stepbar from "./Stepbar";
import Bookinfo from "./popups/Bookinfo";
import Imagesuplods from "./popups/Imagesuplods";
const Worldbook = (props) => {
  const [preinfoCompleted, setPreinfoCompleted] = useState(false);
  const worldbookPreInfo = props.store?.book?.worldbookPreInfo;
  const answer = worldbookPreInfo?.answer;
  const handlePreinfoSave = (answer, alert) => {
    setPreinfoCompleted(false);
  };
  useEffect(() => {
    if (answer?.child_name) {
      setPreinfoCompleted(false);
    } else {
      setPreinfoCompleted("name");
    }
  }, []);

  const handleClick = (componentname) => {
    setPreinfoCompleted(componentname);
  };
  return (
    <div>
      {preinfoCompleted == "name" && <Preinfo onSave={handlePreinfoSave} />}
      {preinfoCompleted == "bookinfo" && (
        <Bookinfo onSave={handlePreinfoSave} />
      )}
      {preinfoCompleted == "image" && (
        <Imagesuplods onSave={handlePreinfoSave} />
      )}
      {!preinfoCompleted && <Worldbookview />}

      {answer?.child_name && answer?.book_title && (
        <Stepbar name={answer?.child_name} handleopen={handleClick} />
      )}
    </div>
  );
};

export default connect(Worldbook);
