import React from "react";

const Stepbar = (props) => {
  const { handleopen = () => {} } = props;
  return (
    <div className="container worldbook-stepbar">
      <div className="row no-gutters space mb-5">
        <div className="col-12">
          <div className="d-flex align-item-center custom-flex">
            <div
              className="item"
              onClick={() => {
                handleopen("name");
              }}
            >
              <p className="title">Edit Name</p>
              <p className="value">{props?.name || "Custom Name"}</p>
            </div>
            <div
              className="item"
              onClick={() => {
                handleopen("bookinfo");
              }}
            >
              <p className="title">Customise your book </p>
              <p className="value">10 Reason Completed</p>
            </div>
            <div
              className="item"
              onClick={() => {
                handleopen("image");
              }}
            >
              <p className="title">Upload your photos </p>
              <p className="value">9 Photos uploaded</p>
            </div>
            <div className="item">
              <button className="continue">Save & Add to cart</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Stepbar);
